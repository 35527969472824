/* eslint-disable no-unused-vars */
import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import SvgIcon from 'components/ui/svg_icon';
import PriceButton from 'components/widgets/price-button';
import PriceLink from 'components/widgets/price-link';
import { useApiData, useAudio } from 'hooks';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { cx, formatSecondsToTime, normalizeUrl } from 'lib/utils';

import styles from './product_card.module.scss';

import { SubScore, useSubScore } from './product/expert-review';
import { IdealScenarios, ProductFeatures } from './product/product-scorecard';

const log = logger({ category: 'n4/ListicleProduct' });

const extractModel = ({ blok, models }) => {
  if (blok.form_factor) {
    return models[blok.form_factor];
  }
  if (blok.hearing_aid) {
    return Object.values(models).find((m) => m.hearing_aids.some((ha) => ha.id === blok.hearing_aid)) || models[`ha-${blok.hearing_aid}`];
  }
  return null;
};

const ListicleProduct = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const isMobileView = useIsMobileView();
  const { models } = useApiData();
  const [model, setModel] = React.useState(extractModel({ blok, models }));

  React.useEffect(() => {
    if (!blok.form_factor && !blok.hearing_aid) {
      setModel(null);
      return;
    }
    const newModel = extractModel({ blok, models });
    if (newModel) {
      setModel(newModel);
      return;
    }
    const fn = async () => {
      const variables = { ids: [blok.form_factor], hearingAidIds: [blok.hearing_aid] };
      const result = await fetchApi({ path: 'releases/models', variables, fallback: {}, origin: 'bloks/n4/product-card' });
      setModel(extractModel({ blok, models: result }));
    };
    fn();
  }, [blok, models]);

  const hearingAid = model?.hearing_aids?.[0];

  const productLink = model?.release?.path
    ? normalizeUrl({ url: model.release.path, origin: `blocks/n4/listicle-product#${blok.form_factor || blok.hearing_aid}` })
    : null;

  const subScoresWithScores = useSubScore(hearingAid?.scores);
  const [isViewMore, setIsViewMore] = React.useState(false);

  if (!model) {
    return null;
  }

  if (!hearingAid) return null;

  const score = model.release_score || model.score;
  const name = blok.form_factor ? model.release.full_name : hearingAid.name;
  const price = blok.form_factor ? model.price : hearingAid.price;
  const image = blok.image?.filename ? blok.image : model.image;

  return (
    <section
      style={{ width: '100%', border: '2px solid red' }}
      {...storyblokEditable(blok)}
      data-form-factor={blok.form_factor || ''}
      data-hearing-aid={blok.hearing_aid || ''}
    >
      <a id={blok._uid} />
      <section>{model.full_name}</section>
      <div style={{ maxWidth: 200 }}>
        <ImageWrapper image={image} />
      </div>
    </section>
  );
};

export default ListicleProduct;
